import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import PortableText from '@/atoms/PortableText';

import { p, h3 } from '@/styles/typography';
import { rem, scale } from '@/styles/utils';

const RichText = styled(Box)`
  ${p}

  h5 {
    ${h3}
  }

  p,
  ul,
  ol {
    max-width: ${scale(40)};

    &:not(:last-child) {
      padding-bottom: ${rem(20)};
    }
  }

  ul,
  ol {
    padding-left: 1.25em;
  }

  ul {
    list-style: disc;
  }

  a {
    border-bottom: 1px solid currentColor;
  }
`;

export default ({ blocksRaw, ...props }) => {
  return (
    <RichText {...props}>
      <PortableText blocks={blocksRaw} />
    </RichText>
  );
};
