import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import RichText from '@/atoms/RichText';
import Heading from '@/atoms/Heading';
import Head from '@/components/Head';

export default ({
  data: {
    page: { title, meta, mainText, secondaryText },
  },
}) => {
  const mainContent = (mainText && mainText.textRaw) || [];
  const secondaryContent = (secondaryText && secondaryText.textRaw) || [];

  return (
    <>
      <Head title={title} meta={meta} />

      <Grid.Container>
        <Heading h={1} hide>
          {title}
        </Heading>

        <Grid>
          <Grid.Item w={[1, 1, 1, 6 / 12]} mb={[3, 3, 0]}>
            <RichText blocksRaw={mainContent} />
          </Grid.Item>

          <Grid.Item w={[1, 1, 1, 5 / 12]} o={[0, 0, 0, 1 / 12]}>
            <RichText blocksRaw={secondaryContent} />
          </Grid.Item>
        </Grid>
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      meta {
        ...metaData
      }
      mainText {
        textRaw: _rawText
      }
      secondaryText {
        textRaw: _rawText
      }
    }
  }
`;
